<template>
  <div class="home">
    <Header />
    <div class="top clearfix">
      <img class="banner"
           src="../components/img/banner.png"
           alt="">
      <div class="content top-text">
        <div>
          <h4 class="title">您专属的融资中心</h4>
          <p class="describe">您专属的融资中心您专属的融资中的融资中您专属的融资中心</p>
          <div class="download-btns">
            <a class="download-btn"
               href="https://www.pgyer.com/hvdC">安卓下载</a>
            <a class="download-btn"
               href="https://apps.apple.com/cn/app/%E4%BF%A1%E7%84%B6/id1445520387">IOS下载</a>
          </div>
        </div>
        <div class="app-img-content">
          <img class="app-img"
               src="../components/img/app.png"
               alt="">
        </div>
      </div>
      <div class="introduce-content content flex-around">
        <div class="item"
             v-for="item in introduceList"
             :key="item.id">
          <h1 class="title"><span class="main-title">{{ item.mainTitle }}</span>&nbsp;&nbsp;{{ item.title }}</h1>
          <p>{{ item.content }}</p>
        </div>
      </div>
    </div>
    <!-- 融资产品类型 -->
    <div class="content product-type">
      <h4 class="title">融资产品类型</h4>
      <div class="product-tab flex-between">
        <div class="item text-center"
             v-for="(item, index) in productTypeList"
             :key="item.id"
             @click="choose(index)">
          <img :src="index === chooseIndex ? item.imgActive : item.img"
               alt="">
          <p class="name"
             :class="{'active': index === chooseIndex}">{{ item.name }}</p>
        </div>
      </div>
      <div class="product flex-between">
        <div class="left">
          <h1 class="product-title">{{ productTypeList[chooseIndex].name }}</h1>
          <div v-html="productTypeList[chooseIndex].content"></div>
        </div>
        <img :src="productTypeList[chooseIndex].contentImg"
             alt="">
      </div>
    </div>
    <!-- 产品匹配及报价 -->
    <div class="content matching-content">
      <h4 class="title">产品匹配及报价</h4>
      <div class="matching-offer flex-between">
        <div class="item">
          <h1 class="item-title">法人名下房产抵押，满成贷</h1>
          <p>
            <span class="sub-title">额度：</span>
            <span>1000万</span>
          </p>
          <p>
            <span class="sub-title">利息：</span>
            <span>3.65%</span>
          </p>
          <p>
            <span class="sub-title">期限：</span>
            <span>3年</span>
          </p>
          <p>
            <span class="sub-title">还款：</span>
            <span>季度付息，每年归本，可申请无还 本续贷</span>
          </p>
          <p>
            <span class="sub-title">注：</span>
            <span>企业除学校项目投资回报较慢其他业 务上下游稳定，盈利能力比较好，所以可 操作满成贷。</span>
          </p>
        </div>
        <div class="item">
          <h1 class="item-title">科技行业，企业信用贷款</h1>
          <p>
            <span class="sub-title">额度：</span>
            <span>500万</span>
          </p>
          <p>
            <span class="sub-title">利息：</span>
            <span>5.2%</span>
          </p>
          <p>
            <span class="sub-title">期限：</span>
            <span>1年</span>
          </p>
          <p>
            <span class="sub-title">还款：</span>
            <span>每月付息，到期归本，到期可再次申请</span>
          </p>
          <p>
            <span class="sub-title">注：</span>
            <span>企业是国家和中关村双高新科技企业，符合政策性扶持产品，故予以批贷。</span>
          </p>
        </div>
        <div class="item">
          <h1 class="item-title">融资租赁业务</h1>
          <p>
            <span class="sub-title">额度：</span>
            <span>500万</span>
          </p>
          <p>
            <span class="sub-title">利息：</span>
            <span>11%</span>
          </p>
          <p>
            <span class="sub-title">期限：</span>
            <span>5年</span>
          </p>
          <p>
            <span class="sub-title">还款：</span>
            <span>等额本息</span>
          </p>
          <p>
            <span class="sub-title">注：</span>
            <span>学校的无人机教学设备及座椅板凳是公司固定资产，且学校目前已初步运营，趋势向好，故租赁业务予以通过。</span>
          </p>
        </div>
      </div>
    </div>
    <!-- 我们的优势 -->
    <div class="superiority-content">
      <img class="superiority-bg"
           src="../components/img/superiority.png"
           alt="">
      <div class="superiority-text content text-center">
        <h4 class="title">我们的优势</h4>
        <div class="flex-between">
          <div class="item">
            <img src="../components/img/module-icon.png"
                 alt="">
            <h5 class="item-name">服务专业</h5>
            <p class="item-des">专业化的服务团队</p>
            <p class="item-des">丰富的服务经验</p>
          </div>
          <div class="item">
            <img src="../components/img/module-icon.png"
                 alt="">
            <h5 class="item-name">流程透明</h5>
            <p class="item-des">工作流程清晰透明</p>
            <p class="item-des">通过信息化手段线上、线下结合的业务模式</p>
          </div>
          <div class="item">
            <img src="../components/img/module-icon.png"
                 alt="">
            <h5 class="item-name">方案多元</h5>
            <p>全程指导准备相关资料</p>
            <p>多种方案联合运用节省时间成本</p>
          </div>
          <div class="item">
            <img src="../components/img/module-icon.png"
                 alt="">
            <h5 class="item-name">资源广泛</h5>
            <p class="item-des">对政策的深度解读</p>
            <p>广泛的合作资源</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="intro content">
      <h4 class="title">公司简介</h4>
      <p>信然信息咨询（北京）有限公司 成立于2013年，是一家致力于服务企业发展的综合性咨询机构。公司成立以来，不断深化与相关政府部门、产业园区、行业协会、银行等金融机构以及各类型企业服务平台的合作。</p>

      <p>通过自主研发的信然贷款产品匹配系统将借款人基本情况、经营情况、信用情况、需求等信息与市场上各类产品的相关要求及评审标准进行匹配，快速、精准地提供融资方案咨询服务。</p>
      <p>公司成立至今，已帮助超3千位客户成功融资截至目前，主要服务于北京、及北京周边等地企业，合作资方包括银行、担保公司、融资租赁、保理、小贷公司、典当及各类消金机构等400余家，贷款产品3000余种。</p>
      <p>信然咨询不断加强企业内部的综合竞争力，在竞争中求发展，在挑战中谋机遇，相信我公司会给您提供最好、最适合您的产品，勤劳和真诚的我们愿
        与您携手并进、共创辉煌！
      </p>
    </div>
    <!-- 发展历程 -->
    <div class="course-content content">
      <h4 class="title">发展历程</h4>
      <div class="flex-around course-year">
        <div class="course-item"
             v-for="(item, index) in courseYearlist"
             :key="item.year"
             @click="chooseYear(index)">
          <img src="../components/img/clock.png"
               alt="">
          <p class="text-center">{{ item.year }}</p>
        </div>
      </div>
      <div class="course-module">
        <img class="comma-top"
             src="../components/img/comma.png"
             alt="">
        <p>{{ courseYearlist[courseIndex].content }}</p>
        <img class="comma-bottom"
             src="../components/img/comma.png"
             alt="">
      </div>
    </div>
    <!-- 公司资质 -->
    <div class="qualifications content">
      <h4 class="title">公司资质</h4>
      <img class="arrow-left"
           src="../components/img/arrow.png"
           @click="slide(1)"
           alt="">
      <div class="img-content">
        <div class="flex-between"
             :style="{left: slideLeft + 'px'}">
          <img src="../components/img/certificate-1.png"
               alt="">
          <img src="../components/img/certificate-2.png"
               alt="">
          <img src="../components/img/certificate-3.png"
               alt="">
          <img src="../components/img/certificate-4.png"
               alt="">
          <img src="../components/img/certificate-5.png"
               alt="">
          <img src="../components/img/certificate-6.png"
               alt="">
        </div>
      </div>
      <img class="arrow-right"
           src="../components/img/arrow.png"
           @click="slide(2)"
           alt="">
    </div>
    <!-- 合作伙伴 -->
    <div class="partnership content">
      <h4 class="title">合作伙伴</h4>
      <div class="partnership-list flex-between">
        <div class="course-item"
             v-for="(item, index) in partnershipList"
             :key="item.id"
             @click="chooseYear(index)">
          <p class="text-center-partner">{{ item.name }}</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      introduceList: [
        {
          id: 1,
          mainTitle: '贷款',
          title: '是件容易的事',
          content: '额度、利息、期限 自选/多选，还在银行、公司间跑来跑去？'
        },
        {
          id: 2,
          mainTitle: '全程透明',
          title: '到账缴费',
          content: '审批进度实时更新 不成功不收费 还在问审批进度、通过、拒绝？'
        },
        {
          id: 3,
          mainTitle: '产品齐全',
          title: '更多选择',
          content: '百家银行产品信息 千家贷款公司服务 还在问什么要求、能不能办？'
        }
      ],
      productTypeList: [
        {
          id: 1,
          img: require('../components/img/credit.png'),
          imgActive: require('../components/img/credit-active.png'),
          name: '纯信用贷',
          content: '<p class="p">发票贷、税金贷、担保贷、房信贷、保险贷、综合信用贷</p>',
          contentImg: ''
        },
        {
          id: 2,
          img: require('../components/img/mortgage.png'),
          imgActive: require('../components/img/mortgage-active.png'),
          name: '抵押贷',
          content: '<p class="p">房产、土地、厂房、存货、知识产权、股权、车辆</p>',
          contentImg: ''
        },
        {
          id: 3,
          img: require('../components/img/insurance.png'),
          imgActive: require('../components/img/insurance-active.png'),
          name: '应收保理',
          content: '<p class="p">明保、暗保、中登</p>',
          contentImg: ''
        },
        {
          id: 4,
          img: require('../components/img/financing.png'),
          imgActive: require('../components/img/financing-active.png'),
          name: '融资租赁',
          content: '<p class="p">直租、回租、代付货款</p>',
          contentImg: ''
        },
        {
          id: 5,
          img: require('../components/img/business.png'),
          imgActive: require('../components/img/business-active.png'),
          name: '票函业务',
          content: '<p class="p">银票、商票、各种保函</p>',
          contentImg: ''
        },
        {
          id: 6,
          img: require('../components/img/customized.png'),
          imgActive: require('../components/img/customized-active.png'),
          name: '量身定制',
          content: '<p class="p">内部融资、项目贷、倒贷、过桥、垫资、提放、置换、其它</p>',
          contentImg: ''
        }
      ], // 产品类型
      chooseIndex: 0, // 默认选择的产品index
      courseYearlist: [
        {
          year: 2013,
          content: '信然信息咨询（北京）有限公司成立。'
        },
        {
          year: 2014,
          content: '产品匹配系统上线。'
        },
        {
          year: 2016,
          content: '系统匹配维度破100项。'
        },
        {
          year: 2018,
          content: '合作机构超1000家。'
        },
        {
          year: 2019,
          content: '成交额破20亿。 '
        }
      ],
      courseIndex: 0, // 发展历程index
      slideLeft: 0, // 左侧距离
      partnershipList: [
        {
          id: 1,
          name: '北京朝阳国际科技创新服务有限公司（朝科创）',
          href: 'https://www.baidu.com/',
          detailsImg: require('../components/img/huawei.png'),
          content: '“某客户通过典当行办理抵押贷款资金周转 ，一段时间后客户因有新项目再次需要资金，但客户已经办理过抵押贷款且房产没有余值不可再次抵押，客户经理将贷款公司的朋友推荐给客户 了解之后发现 手续麻烦且利息很高 客户因有新项目时间安排很满 没有更多精力联系相关业务，客户经理将情况反映到公司，因该公司与信然咨询已建立合作关系，经客户同意后该公司将客户详细情况反馈到信然咨询，信然咨询通过系统加人工匹配迅速拟出融资计划表反馈给该公司，由该公司客户经理提交给客户，因信然咨询在一千多款产品里，匹配出该客户可办理较低利息的产品4款，且全程代办，最终客户选择了2款额度较高息费较低的产品信然咨询全程代办，3个工作日后客户获得了资金。”',
          img: require('../components/img/business-logo.png')
        },
        {
          id: 2,
          name: '中国技术交易所有限公司（北京知识产权交易中心）',
          href: 'https://www.baidu.com/',
          detailsImg: require('../components/img/huawei.png'),
          content: '“某客户通过典当行办理抵押贷款资金周转 ，一段时间后客户因有新项目再次需要资金，但客户已经办理过抵押贷款且房产没有余值不可再次抵押，客户经理将贷款公司的朋友推荐给客户 了解之后发现 手续麻烦且利息很高 客户因有新项目时间安排很满 没有更多精力联系相关业务，客户经理将情况反映到公司，因该公司与信然咨询已建立合作关系，经客户同意后该公司将客户详细情况反馈到信然咨询，信然咨询通过系统加人工匹配迅速拟出融资计划表反馈给该公司，由该公司客户经理提交给客户，因信然咨询在一千多款产品里，匹配出该客户可办理较低利息的产品4款，且全程代办，最终客户选择了2款额度较高息费较低的产品信然咨询全程代办，3个工作日后客户获得了资金。”',
          img: require('../components/img/business-logo.png')
        },
        {
          id: 3,
          name: '燕园校友投资管理有限公司（北京大学创业训练营）',
          href: 'https://www.baidu.com/',
          detailsImg: require('../components/img/huawei.png'),
          content: '“某客户通过典当行办理抵押贷款资金周转 ，一段时间后客户因有新项目再次需要资金，但客户已经办理过抵押贷款且房产没有余值不可再次抵押，客户经理将贷款公司的朋友推荐给客户 了解之后发现 手续麻烦且利息很高 客户因有新项目时间安排很满 没有更多精力联系相关业务，客户经理将情况反映到公司，因该公司与信然咨询已建立合作关系，经客户同意后该公司将客户详细情况反馈到信然咨询，信然咨询通过系统加人工匹配迅速拟出融资计划表反馈给该公司，由该公司客户经理提交给客户，因信然咨询在一千多款产品里，匹配出该客户可办理较低利息的产品4款，且全程代办，最终客户选择了2款额度较高息费较低的产品信然咨询全程代办，3个工作日后客户获得了资金。”',
          img: require('../components/img/business-logo.png')
        },
        {
          id: 4,
          name: '北京中关村高新技术企业协会（高企协）',
          href: 'https://www.baidu.com/',
          detailsImg: require('../components/img/huawei.png'),
          content: '“某客户通过典当行办理抵押贷款资金周转 ，一段时间后客户因有新项目再次需要资金，但客户已经办理过抵押贷款且房产没有余值不可再次抵押，客户经理将贷款公司的朋友推荐给客户 了解之后发现 手续麻烦且利息很高 客户因有新项目时间安排很满 没有更多精力联系相关业务，客户经理将情况反映到公司，因该公司与信然咨询已建立合作关系，经客户同意后该公司将客户详细情况反馈到信然咨询，信然咨询通过系统加人工匹配迅速拟出融资计划表反馈给该公司，由该公司客户经理提交给客户，因信然咨询在一千多款产品里，匹配出该客户可办理较低利息的产品4款，且全程代办，最终客户选择了2款额度较高息费较低的产品信然咨询全程代办，3个工作日后客户获得了资金。”',
          img: require('../components/img/business-logo.png')
        }
      ],
      partnershipIndex: 0 // 合作伙伴index
    };
  },
  methods: {
    choose(index) {
      this.chooseIndex = index;
    },
    chooseYear(index) {
      this.courseIndex = index;
    },
    slide(type) {
      if (type === 1) { // 左滑
        if (this.slideLeft <= 0 && this.slideLeft >= -502) {
          this.slideLeft -= 251;
        }
      } else if (type === 2) { // 右滑
        if (this.slideLeft >= -753 && this.slideLeft <= -251) {
          this.slideLeft += 251;
        }
      }
    },
    partnershipChoose(index) {
      this.partnershipIndex = index;
    }
  }
}
</script>

<style lang="less">
@title-color: #565656;
@content-color: #677d94;
.home {
  .top {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    font-weight: 500;
    color: #ffffff;
    line-height: 66px;
    .banner {
      width: 100%;
      position: relative;
      left: 0;
    }
    .top-text {
      display: flex;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -58%);
      z-index: 1;
      .title {
        font-size: 46px;
        margin-top: 60px;
      }
      .describe {
        font-size: 26px;
      }
      .app-img-content {
        margin-left: 84px;
      }
      .app-img {
        width: 12vw;
        // height: 56vh;
      }
      .download-btns {
        display: flex;
        justify-content: space-between;
        // margin-top: 20px;
        width: 434px;
        position: absolute;
        bottom: 28px;
      }
      .download-btn {
        display: block;
        width: 188px;
        height: 54px;
        line-height: 54px;
        border-radius: 27px;
        background: #4168f4;
        color: #fff;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
      }
    }
    .introduce-content {
      height: 200px;
      background: #ffffff;
      box-shadow: 0px 3px 20px 0px rgba(0, 19, 51, 0.19);
      border-radius: 10px;
      position: absolute;
      left: 50%;
      bottom: -120px;
      transform: translate(-50%, 0);
      z-index: 1;
      padding-top: 48px;
      .item {
        width: 260px;
      }
      .title {
        font-size: 30px;
        color: @title-color;
        padding-bottom: 14px;
      }
      .main-title {
        padding-bottom: 14px;
        border-bottom: 2px solid#007AFF;
      }
      p {
        font-size: 16px;
        color: @content-color;
        line-height: 26px;
      }
    }
  }
  .product-type,
  .matching-content,
  .superiority-content,
  .intro,
  .course-content,
  .qualifications,
  .partnership {
    .title {
      font-size: 44px;
      color: #000000;
      line-height: 67px;
      text-align: center;
      margin: 49px;
    }
  }
  .product-type {
    margin-top: 174px;
    .product-tab {
      padding: 0 20px;
      .item {
        cursor: pointer;
        &:hover {
          animation: scale 0.5s normal;
          animation-fill-mode: forwards;
        }
      }
      .name {
        font-size: 22px;
        color: #000000;
        line-height: 48px;
        position: relative;
        &.active {
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 4px;
            background: #007aff;
          }
        }
      }
    }
    .product {
      .left {
        font-size: 20px;
        line-height: 32px;
        margin-top: 60px;
        color: @content-color;
        .p {
          margin: 26px 0 20px;
        }
      }
      .product-title {
        font-size: 32px;
        color: #000000;
        line-height: 48px;
      }
    }
  }
  .matching-offer {
    .item {
      width: 378px;
      height: 416px;
      background: #ffffff;
      box-shadow: 0px 3px 20px 0px rgba(0, 19, 51, 0.19);
      border-radius: 4px;
      padding: 0 26px;
      cursor: pointer;
      &:hover {
        border: 1px solid #007aff;
        animation: scale 0.5s normal;
        animation-fill-mode: forwards;
      }
      .item-title {
        font-size: 24px;
        color: #007aff;
        margin: 37px 0;
        text-align: center;
      }
      p {
        font-size: 18px;
        line-height: 33px;
        margin-bottom: 5px;
        color: @title-color;
      }
      .sub-title {
        color: @content-color;
      }
    }
  }
  .superiority-content {
    max-width: 1920px;
    position: relative;
    margin: 80px 0 90px;
    color: #fff;
    font-size: 16px;
    .superiority-bg {
      width: 100%;
      position: relative;
    }
    .superiority-text {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      .title {
        color: #fff;
        margin: 30px 0;
      }
    }
    .item {
      cursor: pointer;
      &:hover {
        img {
          animation: turn 0.6s 1;
        }
      }
    }
    .item-name {
      font-size: 22px;
      margin: 10px 0 16px;
    }
  }
  .intro {
    font-size: 18px;
    color: @title-color;
    p {
      text-indent: 2em;
      line-height: 30px;
      margin-bottom: 28px;
    }
  }
  .course-year {
    width: 1200px;
    height: 2px;
    background: linear-gradient(-90deg, #007aff, #f5f9ff);
    border-radius: 1px;
    .course-item {
      margin-top: -19px;
      height: 57px;
      cursor: pointer;
      :hover {
        img {
          animation: turnX 0.6s 1;
        }
      }
    }
  }
  .course-module {
    width: 1000px;
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 19, 51, 0.19);
    border-radius: 10px;
    margin: 78px auto 100px;
    position: relative;
    padding: 60px 50px;
    font-size: 20px;
    color: @content-color;
    .comma-top {
      position: absolute;
      top: -28px;
      left: 50px;
    }
    .comma-bottom {
      position: absolute;
      right: 50px;
      bottom: -25px;
      transform: rotate(180deg);
    }
  }
  .qualifications {
    position: relative;
    .arrow-left {
      position: absolute;
      bottom: 105px;
      left: 0;
    }
    .img-content {
      width: 1076px;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      .flex-between {
        position: relative;
      }
      img {
        margin-right: 23px;
      }
    }
    .arrow-right {
      position: absolute;
      bottom: 105px;
      right: 0;
      transform: rotate(180deg);
    }
  }
  .partnership {
    margin-bottom: 62px;
    .partnership-details {
      height: 440px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 0px rgba(0, 19, 51, 0.19);
      border-radius: 4px;
      margin-bottom: 59px;
      > img {
        margin-right: 43px;
      }
      > div {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: @title-color;
        line-height: 30px;
        img {
          margin-top: 15px;
        }
      }
    }
    .partnership-item {
      cursor: pointer;
      &:hover {
        animation: turnY 0.6s normal;
        animation-fill-mode: forwards;
      }
    }
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }
  @keyframes turnX {
    0% {
      transform: rotateX(0deg);
    }
    100% {
      transform: rotateX(360deg);
    }
  }
  @keyframes turnY {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
  @keyframes turn {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
