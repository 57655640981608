<template>
  <div class="header">
    <div class="content">
      <div class="flex">
        <img class="logo"
             src="../assets/logo.png"
             alt="">
        <p class="logo-text">中小微企业融资服务平台</p>
      </div>
      <div class="phone">
        <img class="phone-icon"
             src="./img/phone.png"
             alt="">
        010-53300337
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {}
  }
}
</script>

<style scoped lang="less">
.header {
  height: 90px;
  line-height: 90px;
  .content {
    display: flex;
    justify-content: space-between;
  }
  .logo {
    width: 88px;
    height: 44px;
    margin: 23px 12px 23px 0;
  }
  .logo-text {
    font-size: 22px;
    font-style: italic;
    color: #999999;
    // line-height: 37px;
  }
  .phone {
    .phone-icon {
      width: 48px;
      height: 48px;
      margin: 21px 13px 21px 0;
    }
    font-size: 30px;
    color: #3c5eaa;
    // line-height: 36px;
  }
}
</style>
